/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import { animated, easings, useSpring } from '@react-spring/web';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateContact } from '../../hooks/useProfile';
import * as AppPropTypes from '../../lib/PropTypes';

import { usePopupsContext } from '../../contexts/PopupsContext';
import BasicButton from '../buttons/BasicButton';
import RoundedButton from '../buttons/RoundedButton';
import PhoneField from '../fields/PhoneField';
import TextField from '../fields/TextField';
import EditIcon from '../icons/EditIcon';
import PlusIcon from '../icons/PlusIcon';
import FormStatus from '../partials/FormStatus';
import FormControl from './FormControl';
import VerifyContactForm from './VerifyContactForm';

import styles from '../../styles/forms/edit-contact-form.module.css';

const propTypes = {
    type: PropTypes.string,
    label: PropTypes.node,
    editOnly: PropTypes.bool,
    withoutVerify: PropTypes.bool,
    inputRef: AppPropTypes.ref,
    onComplete: PropTypes.func,
    onEdit: PropTypes.func,
    onClickCancel: PropTypes.func,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'email',
    label: null,
    editOnly: false,
    withoutVerify: false,
    inputRef: null,
    onComplete: null,
    onEdit: null,
    onClickCancel: null,
    inputClassName: null,
    className: null,
};

function EditContact({
    type,
    label,
    editOnly,
    withoutVerify,
    inputRef: providedInputRef,
    onComplete: customOnComplete,
    onClickCancel: customOnCancel,
    onEdit,
    inputClassName,
    className,
}) {
    const { addMessage } = usePopupsContext();
    const user = useUser();
    const { updateContactAsync } = useUpdateContact();
    const { contacts = [] } = user || {};
    const pendingContact =
        contacts.find(({ type: contactType, pending = true }) => contactType === type && pending) ||
        null;

    // const [isEditing, setIsEditing] = useState(pendingContact === null);
    const [isEditing, setIsEditing] = useState(false);
    const finalIsEditing = editOnly || isEditing;

    const inputRef = useRef(null);

    const formFields = useMemo(() => ['type', 'value'], []);
    const initialValue = useMemo(
        () => ({
            type,
            value:
                // eslint-disable-next-line no-nested-ternary
                pendingContact !== null ? pendingContact.value : user !== null ? user[type] : null, // @TODO
        }),
        [user, type, pendingContact],
    );

    const onComplete = useCallback(
        (newUser) => {
            const { contacts: newContacts = [] } = newUser || {};
            const newPendingContact =
                newContacts.find(
                    ({ type: contactType, pending }) => contactType === type && pending,
                ) || null;

            const isPending = newPendingContact !== null;

            if (!isPending && customOnComplete !== null) {
                customOnComplete(newUser);
            }

            addMessage(isPending && !withoutVerify ? 'verify_contact' : 'contact_updated', {
                type,
            });

            setIsEditing(false);
        },
        [customOnComplete, type, setIsEditing, addMessage],
    );

    const { fields, onSubmit, status, errors, setValue, setErrors } = useForm({
        fields: formFields,
        value: initialValue,
        postForm: (formAction, data) => updateContactAsync(data),
        onComplete,
    });

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const { value: valueField = null } = fields || null;
    const { value: contactValue = null } = valueField || null;

    const onClickAdd = useCallback(() => {
        let canEdit = true;
        if (onEdit !== null) {
            canEdit = onEdit();
        }
        if (canEdit !== false) {
            setIsEditing(true);
        }
    }, [setIsEditing, onEdit]);

    const onClickEdit = useCallback(() => {
        let canEdit = true;
        if (onEdit !== null) {
            canEdit = onEdit();
        }
        if (canEdit !== false) {
            setIsEditing(true);
        }
    }, [setIsEditing, onEdit]);

    const onVerifyComplete = useCallback(
        (newUser) => {
            if (customOnComplete !== null) {
                customOnComplete(newUser);
            }
        },
        [customOnComplete],
    );

    const onCancel = useCallback(() => {
        setIsEditing(false);
        setErrors(null);

        if (pendingContact === null) {
            setValue(initialValue);

            if (customOnCancel !== null) {
                customOnCancel();
            }
        } else {
            setValue(null);
        }
    }, [setIsEditing, pendingContact, customOnCancel]);

    useEffect(() => {
        if (finalIsEditing && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [finalIsEditing, initialValue]);

    const Field = type === 'phone' ? PhoneField : TextField;

    const buttonsStyle = useSpring({
        config: {
            duration: 500,
            easing: easings.easeOutCubic,
        },
        to: finalIsEditing
            ? {
                  y: 0,
                  opacity: 1,
              }
            : {
                  y: -10,
                  opacity: 0,
              },
    });

    const withVerifyToken = pendingContact !== null && !withoutVerify;

    const verifyStyle = useSpring({
        config: {
            duration: 500,
            easing: easings.easeOutCubic,
        },
        to: withVerifyToken
            ? {
                  y: 0,
                  opacity: 1,
              }
            : {
                  y: -20,
                  opacity: 0,
              },
    });

    return (
        <div className={classNames([styles.container, className])}>
            <FormControl
                label={
                    label !== false
                        ? label ||
                          (type === 'phone' ? (
                              <FormattedMessage
                                  defaultMessage="Téléphone"
                                  description="Field label"
                              />
                          ) : (
                              <FormattedMessage
                                  defaultMessage="Courriel"
                                  description="Field label"
                              />
                          ))
                        : null
                }
            >
                <div className={styles.row}>
                    {finalIsEditing ? (
                        <form
                            onSubmit={onSubmit}
                            className={classNames([
                                styles.form,
                                {
                                    'was-validated': errors !== null,
                                },
                            ])}
                        >
                            <FormControl
                                {...valueField}
                                withoutErrors
                                className={classNames([
                                    styles.formControl,
                                    {
                                        [styles.hasStatus]: status !== null,
                                    },
                                ])}
                            >
                                <div className={styles.field}>
                                    <Field
                                        {...valueField}
                                        required
                                        id={type}
                                        name={type}
                                        ref={providedInputRef || inputRef}
                                        withBorder
                                        className={
                                            type !== 'phone'
                                                ? classNames([styles.input, inputClassName])
                                                : null
                                        }
                                        inputClassName={
                                            type === 'phone'
                                                ? classNames([styles.input, inputClassName])
                                                : null
                                        }
                                    />
                                    <FormStatus status={status} className={styles.status} />
                                </div>
                            </FormControl>
                            <animated.div className={styles.buttons} style={buttonsStyle}>
                                <div className={styles.buttonsInner}>
                                    <RoundedButton
                                        type="submit"
                                        dark
                                        disabled={status === 'loading'}
                                        className={styles.button}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Enregistrer"
                                            description="Button label"
                                        />
                                    </RoundedButton>
                                    <RoundedButton
                                        transparent
                                        compact
                                        onClick={onCancel}
                                        className={styles.button}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Annuler"
                                            description="Button label"
                                        />
                                    </RoundedButton>
                                </div>
                            </animated.div>
                        </form>
                    ) : (
                        <div
                            className={classNames([
                                styles.value,
                                {
                                    [styles.hasValue]: !isEmpty(contactValue),
                                },
                                inputClassName,
                            ])}
                        >
                            {!isEmpty(contactValue) ? (
                                <div className={styles.placeholder}>{contactValue}</div>
                            ) : null}
                            {isEmpty(contactValue) ? (
                                <BasicButton
                                    className={classNames([styles.button, styles[type]])}
                                    onClick={onClickAdd}
                                >
                                    <PlusIcon className={styles.icon} />
                                    {type === 'phone' ? (
                                        <FormattedMessage
                                            defaultMessage="Ajouter un numéro de téléphone"
                                            description="Button label"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="Ajouter un courriel"
                                            description="Button label"
                                        />
                                    )}
                                </BasicButton>
                            ) : null}
                            {!isEmpty(contactValue) ? (
                                <BasicButton className={styles.button} onClick={onClickEdit}>
                                    <EditIcon className={styles.icon} />
                                    <FormattedMessage
                                        defaultMessage="Modifier"
                                        description="Button label"
                                    />
                                </BasicButton>
                            ) : null}
                        </div>
                    )}

                    {withVerifyToken ? (
                        <animated.div style={verifyStyle} className={styles.verify}>
                            <VerifyContactForm
                                contact={pendingContact}
                                onComplete={onVerifyComplete}
                                onCancel={onCancel}
                                className={styles.verifyForm}
                                inputClassName={styles.verifyInput}
                            />
                        </animated.div>
                    ) : null}
                </div>
            </FormControl>
        </div>
    );
}

EditContact.propTypes = propTypes;
EditContact.defaultProps = defaultProps;

export default EditContact;
