/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import MicromagSummary from '../summaries/MicromagSummary';
import DocumentFeaturedCard from './DocumentFeaturedCard';

import styles from '../../styles/cards/micromag-featured-card.module.css';

const propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    imageContainerClassName: PropTypes.string,
};

const defaultProps = {
    title: null,
    slug: null,
    className: null,
    labelClassName: null,
    imageContainerClassName: null,
};

function MicromagFeaturedCard({
    slug,
    className,
    labelClassName,
    imageContainerClassName,
    title,
    ...props
}) {
    const url = useUrlGenerator();
    return (
        <DocumentFeaturedCard
            {...props}
            slug={slug}
            title={title}
            className={classNames(styles.container, className)}
            labelClassName={classNames(styles.label, labelClassName)}
            imageContainerClassName={classNames(styles.imageContainer, imageContainerClassName)}
            label={
                <MicromagSummary
                    link={slug !== null ? url('micromag', { slug }) : null}
                    title={title}
                    className={styles.summary}
                    titleClassName={styles.summaryTitle}
                    descriptionClassName={styles.summaryDescription}
                    buttonClassName={styles.summaryButton}
                />
            }
        />
    );
}

MicromagFeaturedCard.propTypes = propTypes;
MicromagFeaturedCard.defaultProps = defaultProps;

export default MicromagFeaturedCard;
